import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { momentDE } from "../../functions/functions.js";
import i18next from "../../i18n.js";
import Markdown from "../Markdown.js";
import EventTileSmall from "../../components/eventTileSmall";
import InqueryForm from "../inquiryForm";
import SidebarLayout from "../../components/sidebarLayout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SeoEvent from "../seoEvent.js";
import {
  EventSchedule,
  Hourglass,
  Settings,
  Money,
  Education,
  PedestrianFamily,
  SkillLevel,
  EarthFilled,
} from "@carbon/icons-react";

import * as Styles from "../../styles/training.module.scss";

const SingleTraining = ({ pageContext }) => {
  var moment = momentDE();
  var translationContext = { locale: pageContext.locale };
  var { t } = i18next;
  // var [selectedEvent, setSelectedEvent] = useState("");

  const trainingData = pageContext.data.frontmatter;
  const trainingBody = pageContext.data.body;
  var futureEvents = trainingData.Events?.filter(
    (a) => new Date(a.StartDate) >= new Date()
  ).sort(
    (a, b) =>
      new moment(a.StartDate).format("DD") >
      new moment(b.StartDate).format("DD")
  );
  futureEvents?.forEach((event) => {
    event.Price = event.Price ? event.Price : trainingData.Price;
    event.Duration = trainingData.Duration;
    event.EndDate = new moment(event.StartDate)
      .locale("de")
      .add(event.Duration - 1, "days")
      .format("YYYY-MM-DD");
    event.StartDate = new moment(event.StartDate)
      .locale("de")
      .format("YYYY-MM-DD");
    event.Remark = event.Remark;
  });
  var nextOpenTraining = futureEvents ? futureEvents[0] : null;

  return (
    <SidebarLayout
      title={trainingData.Title}
      pageContext={pageContext}
      hideTitle={true}
      translationContext={translationContext}
      main={
        <div class="training">
          {nextOpenTraining ? (
            <SeoEvent
              title={trainingData.Title}
              description={trainingData.Excerpt}
              isEvent={true}
              eventData={{
                Start_Date_ISO: nextOpenTraining?.StartDate,
                End_Date_ISO: nextOpenTraining?.EndDate,
                Price: trainingData.Price,
              }}
              locale="de"
              pageContext={pageContext}
              imagePath={
                trainingData.Image.childImageSharp.gatsbyImageData.images
                  .fallback.src
              }
            />
          ) : null}
          <div class={Styles.trainingHeader}>
            <GatsbyImage
              image={getImage(trainingData.Image)}
              style={{ gridArea: "1/1" }}
            ></GatsbyImage>
            <div>
              <h1>{t("training") + " " + trainingData.Title}</h1>
            </div>
          </div>
          <div class={Styles.eventAttributeColumns}>
            <div class={Styles.eventAttributeList}>
              <h4>Allgemein</h4>
              <div class={Styles.eventAttributeLine}>
                <Hourglass size={24} />
                <div>Dauer: {trainingData.Duration} Tage</div>
              </div>
              <div class={Styles.eventAttributeLine}>
                <SkillLevel size={24} />
                <div>Level: {trainingData.Level}</div>
              </div>
              <div class={Styles.eventAttributeLine}>
                <Education size={24} />
                <div>
                  Trainer:{" "}
                  {nextOpenTraining?.Trainer
                    ? nextOpenTraining.Trainer
                    : trainingData.Trainer}
                </div>
              </div>

              <div class={Styles.eventAttributeLine}>
                <Settings size={24} />
                <div>
                  Art: Vor Ort, Remote{" "}
                  {nextOpenTraining ? ", offene Schulung" : null}
                </div>
              </div>
            </div>
            {nextOpenTraining ? (
              <div class={Styles.eventAttributeList}>
                <h4>Nächste offene Schulungen</h4>
                <div class={Styles.eventAttributeLine + " " + Styles.tooltip}>
                  <EventSchedule size={24} />
                  <span class={Styles.tooltipText}>
                    Nächste offene Schulung
                  </span>
                  <div>
                    Nächster Termin:{" "}
                    {new moment(nextOpenTraining.StartDate).format("D.")} -{" "}
                    {new moment(nextOpenTraining.StartDate)
                      .locale("de")
                      .add(trainingData.Duration - 1, "days")
                      .format("D. MMMM YYYY")}
                  </div>
                </div>

                <div class={Styles.eventAttributeLine}>
                  <Money size={24} />
                  <div>
                    Kosten:{" "}
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(
                      (nextOpenTraining.Price
                        ? nextOpenTraining.Price
                        : trainingData.Price) * 0.75
                    ) +
                      " - " +
                      new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(
                        nextOpenTraining.Price
                          ? nextOpenTraining.Price
                          : trainingData.Price
                      )}
                  </div>
                </div>
                <div class={Styles.eventAttributeLine}>
                  <EarthFilled size={24} />
                  <div>
                    Ort:{" "}
                    {nextOpenTraining.Location
                      ? nextOpenTraining.Location
                      : trainingData.Location}
                  </div>
                </div>
                {/*<div class={Styles.eventAttributeLine}>
                <PedestrianFamily size={24} />
                <div style="color: grey;">Anmeldungen:  3/10</div>
                  </div>*/}
              </div>
            ) : null}
          </div>
          <div class="main-padding">
            <Markdown>{trainingData.Excerpt}</Markdown>
            <MDXRenderer>{trainingBody}</MDXRenderer>
            {trainingData.Content ? (
              <div class={Styles.agendaList}>
                <h2>{t("trainingEventAgenda")}</h2>
                {trainingData.Content.length == 1 ? (
                  <div>
                    <Markdown>{trainingData.Content[0]}</Markdown>
                  </div>
                ) : (
                  <ul>
                    {trainingData.Content.map((Topic) =>
                      Topic.substring(0, 1) === "-" ? (
                        <ul>
                          <li>
                            <Markdown>{Topic.substring(1)}</Markdown>
                          </li>
                        </ul>
                      ) : (
                        <li>
                          <Markdown>{Topic}</Markdown>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            ) : null}
            {trainingData.OpenTrainingInfo ? (
              <>
                <h3>Informationen für Offene Schulungen</h3>
                <Markdown>{trainingData.OpenTrainingInfo}</Markdown>
              </>
            ) : null}
            {trainingData.Prerequisites ? (
              <>
                <h3>Teilnahmevoraussetzungen</h3>
                <Markdown>{trainingData.Prerequisites}</Markdown>
              </>
            ) : null}
            {trainingData.TargetAudience ? (
              <>
                <h3>Zielgruppe</h3>
                <Markdown>{trainingData.TargetAudience}</Markdown>
              </>
            ) : null}
          </div>
        </div>
      }
      sidebar={
        <div>
          <div>
            <div id="inquiry" class="anchor" />
            <InqueryForm
              topic={trainingData.Title}
              title={t("trainingInquiryHeading1")}
              futureEvents={futureEvents}
            />
          </div>
        </div>
      }
    />
  );
};
export default SingleTraining;

import  React from 'react'
import { EventSchedule } from "@carbon/icons-react"
import * as Styles from '../styles/eventTiles.module.scss'

const EventTileSmall = ({ title,
    date,
    slug,
    minPrice,
    location,
    priceCondition,
    data }) => {
    return (
        <div class={Styles.eventTileSmall}
             role="menuitem"
             aria-label={title}
             tabIndex={0}>
               
            <div to={slug}>
                <div class={Styles.columns}>
                    <EventSchedule size={32} />
                    <div>
                        <p>{title}</p>
                        <p><b>{date}</b></p>
                        {minPrice?(<p>Ab {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(minPrice ) } {priceCondition}</p>):null}
                        {location?(<p>{location}</p>):null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EventTileSmall 
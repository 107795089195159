import React from 'react'
import { Helmet } from 'react-helmet'
import SeoBreadcrumb from './seoBreadcrumb'
import SeoTwitter from './seoTwitter'
import {organization, person, siteUrl} from './seoDefinitions'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SeoEvent = ({ title, 
              description, 
              imagePath, 
              eventData,  
              locale, 
             pageContext }) => {

              const image = `${siteUrl}${imagePath}`

  let schemaEvent = {
      '@context': 'http://schema.org',
      '@type': 'Event', 
      "name": title,//
      "description": description,
      "eventStatus": "EventScheduled",
      "eventAttendanceMode": "OfflineEventAttendanceMode",
      "performer": {
        "@type": "Person",
        "name": "Jörg Brandeis",
        "sameAs": "https://www.linkedin.com/in/joerg-brandeis/"
      },
      "startDate": eventData.Start_Date_ISO,
      "endDate":  eventData.End_Date_ISO,
      "offers": {
        "@type": "Offer",
        "name": "Ticket",
        "category": "primary",
        url: `${siteUrl}${pageContext.breadcrumb.location}`,
        "price": eventData.Price,
        "priceCurrency": "EUR",
        "availability": "InStock",
        "validFrom": "2022-03-01"
      },
      organizer: organization,
      "location": {
        "@type": "Place",
        "name": "Mafinex Technologiezentrum",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Julius-Hatry-Str. 1",
          "addressLocality": "Mannheim",
          "addressRegion": "Baden-Württemberg",
          "postalCode": "68163",
          "addressCountry": "DE"
        }
      },
      image: {
        '@type': 'ImageObject',
        url: `${siteUrl}${imagePath}`,
      },
    }


  return (
    <>
      <Helmet title={title}>
        <html lang={locale} />
        <meta name="image"       property="og:image"       content={image} />
        <meta name="description" property="og:description" content={description} />
        <meta name="title"       property="og:title"       content={title} />
        <meta property="og:url" content={`${siteUrl}${pageContext.breadcrumb.location}`} />
        <meta name="publish_date" property="og:publish_date" content="2024-01-21T00:00:00-0600"></meta>
        <meta name="author" content="Jörg Brandeis" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) / */}
        <script type="application/ld+json">{JSON.stringify(schemaEvent)}</script>
      </Helmet>
      <SeoTwitter title={title} image={image} desc={description} username={person.name} />      
      <SeoBreadcrumb pageContext={pageContext}
                     title={title}/>
    </>
  )
}

export default SeoEvent
